/* eslint-disable consistent-return */
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const removeTrailingSlashes = (string) => {
  if (string === null) {
    return null;
  }
  return string.replace(/\/+$/, '');
};

const Link = (props) => {
  if (typeof props.to === 'undefined') {
    return props.children;
  }

  const getPathName = () => {
    if (typeof window === 'undefined') {
      return;
    }
    return window.location?.pathname;
  };

  const ExternalRegex = /(https?:\/\/[^\s]+)/;
  const MailtoRegex = /(mailto:[^\s]+)/;
  const TelRegex = /(tel:[^\s]+)/;

  if (
    typeof props.to === 'string' &&
    !ExternalRegex.test(props.to) &&
    !MailtoRegex.test(props.to) &&
    !TelRegex.test(props.to)
  ) {
    if (props.to === 'home') {
      return (
        <GatsbyLink state={{ prevPath: getPathName() }} {...props} to="/">
          {props.children}
        </GatsbyLink>
      );
    }
    return (
      <GatsbyLink
        state={{ prevPath: getPathName() }}
        {...props}
        to={`/${removeTrailingSlashes(props.to)}`}
      >
        {props.children}
      </GatsbyLink>
    );
  }

  return (
    <a target={props.target ?? '_blank'} {...props} href={removeTrailingSlashes(props.to)}>
      {props.children}
    </a>
  );
};

export default Link;
