import { createGlobalStyle } from 'styled-components';
import * as mixins from './mixins';

export default createGlobalStyle`
    html {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    *, *:before, *:after {
        -webkit-box-sizing: inherit;
        -moz-box-sizing: inherit;
        box-sizing: inherit;
    }
    body[data-drawer="open"] {
        overflow: hidden;
    }
    .container {
        ${mixins.makeContainer()}
        ${mixins.makeContainerMaxWidths()}
    }
    .row {
        ${mixins.makeRow()}
    }
    .col {
        ${mixins.makeColReady()}
        ${mixins.makeCol()}
    }
`;
