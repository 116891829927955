import React, { useContext } from 'react';
import styled from 'styled-components';
import ThemeContext from '~contexts/ThemeContext';

import SocialIcon from '../../../Button/SocialIcon';
import * as mixins from '../../../../styles/mixins';

import SanitizeHTML from '~components/util/SanitizeHTML';
import Api from '~components/../util/storyblok-api';

const Wrapper = styled.div`
  background: ${({ theme }) => {
    return theme.colors.grey;
  }};

  border-top: 12px solid
    ${({ theme }) => {
      return theme.colors.secondary;
    }};
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-top:1rem;
  }
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const BottomRow = styled.div`
  ${mixins.makeRow()}

  padding-top:40px;
  padding-bottom: 70px;
`;

const SocialCol = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol(12, 12)}
  a + a {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  width: auto;
  flex-shrink: 0;

  @media ${({ theme }) => theme.breakpoint.md} {
    a + a {
      margin-left: 2rem;
      margin-right: 1.5rem;
    }

    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0rem;

    display: flex;
    // justify-content: space-between;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

const AdditionalInfo = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;

  font-size: 9px;
  line-height: 11px;
`;

const FooterComponent = ({ additionalInfo, children }) => {
  const { themeState } = useContext(ThemeContext);
  return (
    <Wrapper>
      <Container>
        <Row>{children}</Row>
        <BottomRow>
          <SocialCol>
            {/* {themeState?.whatsapp && themeState.whatsapp !== '' && (
              <SocialIcon href={themeState.whatsapp} buttonSize={32} icon="whatsapp" />
            )} */}
            {themeState?.instagram && themeState.instagram !== '' && (
              <SocialIcon href={themeState.instagram} buttonSize={32} icon="instagram" />
            )}
            {themeState?.facebook && themeState.facebook !== '' && (
              <SocialIcon href={themeState.facebook} buttonSize={32} icon="facebook" />
            )}
            {themeState?.linkedin && themeState.linkedin !== '' && (
              <SocialIcon href={themeState.linkedin} buttonSize={32} icon="linkedin" />
            )}
            <AdditionalInfo>
              <SanitizeHTML html={Api.richTextResolver.render(additionalInfo)} nomargin />
            </AdditionalInfo>
          </SocialCol>
        </BottomRow>
      </Container>
    </Wrapper>
  );
};

export default FooterComponent;
