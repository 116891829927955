/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import Title from '../../ServicesPage/WebDesign/components/Title';
import Button from './Button';

import ContactButtons from './ContactButtons';

import * as mixins from '../../../../styles/mixins';

const FluidContainer = styled.div`
  ${mixins.makeContainer()}
  ${({ theme, borderBottom }) =>
    borderBottom === true &&
    `border-bottom: 18px solid ${theme.colors.grey200};`}
    

    padding-top: 36px;
  padding-bottom: 36px;

  padding-left: 0;
  padding-right: 0;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-left: 0;
    padding-right: 0;
  }
  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 80px;
    padding-bottom: 100px;
  }
`;

const Container = styled.div`
  ${mixins.makeContainer()}
  ${mixins.makeContainerMaxWidths()}
`;

const Row = styled.div`
  ${mixins.makeRow()}
`;

const UserFeedback = styled.div`
  margin-top: 1rem;

  color: red;
`;

const Col = styled.div`
    ${mixins.makeColReady()}
    ${mixins.makeCol()}
    ${({ theme, lg, xl }) => {
      return `
            @media ${theme.breakpoint.lg} {
                ${mixins.makeCol(lg ?? 12, 12)}
            }
            @media ${theme.breakpoint.xl} {
                ${mixins.makeCol(xl ?? 12, 12)}
            }
        `;
    }}
`;

const TitleWrapper = styled.div`
    ${mixins.makeCol()}
    @media ${({ theme }) => theme.breakpoint.xl} {
        ${mixins.makeCol(5, 6)}
    }
`;

const FormWrapper = styled.div`
  font-size: 20px;
  line-height: 32px;
  @media ${({ theme }) => theme.breakpoint.md} {
    line-height: 40px;
  }

  padding-bottom: 20px;
`;

const FormInput = styled.input`
  ::placeholder,
  ::-webkit-input-placeholder {
    transition: 0.3s ease-in-out color;
  }
  &[value='']:focus {
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${({ theme }) => theme.colors.secondary}33; /* its rgba a=0.2 */
    }
  }

  color: ${({ theme }) => theme.colors.secondary};
  border: 0;

  border-radius: 0 !important;

  font-size: 16px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
  :-moz-placeholder,
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &.invalid {
    ::placeholder,
    ::-webkit-input-placeholder {
      color: red;
    }
    :-moz-placeholder,
    :-ms-input-placeholder {
      color: red;
    }

    color: red;
    border-bottom: 1px solid red;
  }
`;

const FormSelect = styled.select`
  color: ${({ theme }) => theme.colors.secondary};
  border: 0;

  width: 250px;

  font-size: 16px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='${({
    theme,
  }) => theme.colors.secondary}'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  @media ${({ theme }) => theme.breakpoint.md} {
    background: initial;

    -webkit-appearance: menulist-button;
    -moz-appearance: menulist-button;
    appearance: menulist-button;
  }

  border-radius: 0 !important;

  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }
  :-moz-placeholder,
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &.invalid {
    ::placeholder,
    ::-webkit-input-placeholder {
      color: red;
    }
    :-moz-placeholder,
    :-ms-input-placeholder {
      color: red;
    }

    color: red;
    border-bottom: 1px solid red;
  }
`;

const DesktopBr = styled.br`
  display: none;
  @media ${({ theme }) => theme.breakpoint.lg} {
    display: initial;
  }
`;

const ContactForm = ({ modal, headingTag }) => {
  const [formComplete, setFormComplete] = useState(false);
  const [formState, setFormState] = useState(false);

  const formRef = useRef(null);

  const SuccessResponse = () => {
    return (
      <h2>
        Thank you for your message. <br />
        <small>A member of the team will be in touch soon</small>
      </h2>
    );
  };

  const ErrorResponse = () => {
    return (
      <>
        <h2>
          There was a problem with sending your message. <br />
          <small>Please try again later.</small>
        </h2>
      </>
    );
  };

  const onButtonClickHandler = () => {
    const formData = new FormData(formRef.current);

    fetch('//forms.mkpactive.com/mail-contact.php', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Something went wrong');
      })
      .then((data) => {
        setFormComplete(true);
        // do something awesome that makes the world a better place
        if (data.status === true) {
          setFormState(true);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'Form Submitted',
            category: 'Main Contact Form',
            action: 'Submit',
            label: 'Gatsby',
          });
        } else {
          setFormState(false);
        }
      })
      .catch(() => {
        setFormComplete(true);
        setFormState(false);
      });
  };

  const FormComponent = ({ fRef }) => {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');

    const [error, setError] = useState('');

    const [formDisable, setFormDisable] = useState(false);

    const onClickHandler = () => {
      if (!formDisable) {
        if (formRef.current.checkValidity()) {
          setFormDisable(true);
          onButtonClickHandler();
        }
      }
    };

    const onInvalidHandler = (e) => {
      let flag = false;
      // formRef interate inputs check focus
      e.target.classList.add('invalid');

      const inputs = fRef.current.querySelectorAll('input, select');
      inputs.forEach((input) => {
        if (input === document.activeElement) {
          flag = true;
        }
      });

      if (flag === false) {
        e.target.focus();

        setError(e.target.validationMessage);
      }
    };

    const onInputHandler = (e) => {
      setError('');
      e.target.classList.remove('invalid');
    };

    const getSelectField = () => {
      return (
        <FormSelect
          onInvalid={onInvalidHandler}
          onInput={onInputHandler}
          name="service"
          defaultValue=""
          required
        >
          <option value="" disabled hidden>
            Choose a service
          </option>
          <option value="SEO">SEO</option>
          <option value="PPC">PPC</option>
          <option value="Social Media">Social Media</option>
          <option value="Website Design and Dev">Website Design &amp; Dev</option>
          <option value="Content Creation">Content Creation</option>
          <option value="Other">Other</option>
        </FormSelect>
      );
    };

    return (
      <form ref={fRef}>
        <FormWrapper>
          My full name is{' '}
          <FormInput
            value={name}
            onInvalid={onInvalidHandler}
            onInput={onInputHandler}
            name="name"
            onChange={(e) => {
              setName(e.target.value);
            }}
            type="input"
            placeholder="enter your name"
            required
          />
          .
          <DesktopBr /> I work for{' '}
          <FormInput
            type="input"
            name="company"
            onInvalid={onInvalidHandler}
            onInput={onInputHandler}
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
            placeholder="enter your company"
            required
          />{' '}
          and I&apos;m in need of a <DesktopBr />
          partner to help me with {getSelectField()}. <DesktopBr />
          You can reach me at{' '}
          <FormInput
            name="email"
            value={email}
            onInvalid={onInvalidHandler}
            onInput={onInputHandler}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            size={22}
            type="email"
            placeholder="enter your email address"
            required
          />{' '}
          to get the <DesktopBr />
          conversation started.
        </FormWrapper>
        <Button onClick={onClickHandler}>Let&apos;s talk</Button>
        <UserFeedback>{error}</UserFeedback>
      </form>
    );
  };

  return (
    <FluidContainer borderBottom={!modal}>
      <Container>
        <Row>
          <Col lg={5} xl={6}>
            <TitleWrapper>
              <Title useTag={headingTag ?? `h2`} style={{ marginTop: '0' }}>
                Ready to grow your brand?
                <br />
                {modal === true ? (
                  <span>
                    We should talk<span className="title-pink">.</span>
                  </span>
                ) : (
                  <span className="title-grey">We should talk.</span>
                )}
              </Title>
            </TitleWrapper>
            {modal && <ContactButtons mobile />}
          </Col>
          <Col lg={7} xl={6}>
            {formComplete ? (
              formState ? (
                <SuccessResponse />
              ) : (
                <ErrorResponse />
              )
            ) : (
              <FormComponent fRef={formRef} />
            )}
            {modal && <ContactButtons />}
          </Col>
        </Row>
      </Container>
    </FluidContainer>
  );
};

export default ContactForm;
