import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const duration = 0.5;

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration,
      delay: duration,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    y: 100,
    transition: { duration },
  },
};

const PageTransition = ({ children, location }) => {
  if (typeof location === 'undefined') {
    return children;
  }
  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        key={location.pathname}
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default PageTransition;
