/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable consistent-return */
import React, { useState, useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Link from '~components/util/Link';

import DrawerContext from '~contexts/DrawerContext';
import Arrow from '../../util/arrow';

const SubLink = styled(Link)`
  display: flex;
  align-items: center;

  line-height: 0.8rem;
  font-size: 0.8rem;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: initial;
    line-height: initial;
  }
  outline: 0 !important; /* Storyblok outline there was annoying */
  box-shadow: none !important;

  width: auto;
  &:focus {
    outline: 0;
  }

  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};

    svg g {
      stroke: ${({ theme }) => theme.colors.secondary};
    }
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const MenuSubItem = ({ url, label }) => {
  const themeContext = useContext(ThemeContext);
  const { drawerDispatch } = useContext(DrawerContext);
  const [strokeWidth, setStrokeWidth] = useState(0.9);
  const [isMobile, setMobile] = useState(false);

  const onMouseEnterHandler = () => {
    setStrokeWidth(1.5);
  };
  const onMouseLeaveHandler = () => {
    setStrokeWidth(0.9);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const checkIfMobile = () => {
        if (window.innerWidth < 768) {
          setMobile(true);
        } else {
          setMobile(false);
        }
      };
      checkIfMobile();
      window.addEventListener('resize', checkIfMobile);
      return () => {
        window.removeEventListener('resize', checkIfMobile);
      };
    }
    return null;
  }, []);

  const getPathName = () => {
    if (typeof window === 'undefined') {
      return;
    }
    return location?.pathname;
  };

  return (
    <SubLink
      to={url}
      state={{ prevPath: getPathName() }}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      onClick={() => drawerDispatch({ type: 'close' })}
    >
      <Arrow
        className="arrow-item"
        size={isMobile ? 25 : 32}
        width={strokeWidth}
        color={themeContext.colors.primary}
      />
      {label}
    </SubLink>
  );
};

export default MenuSubItem;
