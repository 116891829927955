import { createGlobalStyle } from 'styled-components';

import fonts from '../assets/fonts';

export default createGlobalStyle`
    @font-face {
        font-family: 'Avenir Next';
        src: url(${fonts.ultraLightItalic2}) format('woff2'),
            url(${fonts.ultraLightItalic}) format('woff');
        font-weight: 200;
        font-style: italic;
        font-display: swap;
    }


    @font-face {
        font-family: 'Avenir Next';
        src: url(${fonts.boldItalic2}) format('woff2'),
            url(${fonts.boldItalic}) format('woff');
        font-weight: bold;
        font-style: italic;
        font-display: swap;
    }


    @font-face {
        font-family: 'Avenir Next';
        src: url(${fonts.ultraLight2}) format('woff2'),
            url(${fonts.ultraLight}) format('woff');
        font-weight: 200;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Avenir Next';
        src: url(${fonts.italic2}) format('woff2'),
            url(${fonts.italic}) format('woff');
        font-weight: normal;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Avenir Next';
        src: url(${fonts.regular2}) format('woff2'),
            url(${fonts.regular}) format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Avenir Next';
        src: url(${fonts.bold2}) format('woff2'),
            url(${fonts.bold}) format('woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

`;
