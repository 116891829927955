import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
// import { Link } from 'gatsby';
import Link from '../util/Link';

import Arrow from '../util/arrow';
import SocialIcon from '~components/Button/SocialIcon';

const StyledArrow = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
display: inline-flex;

align-items: center;
justify-content: center;
  white-space: nowrap;
  border-radius: 999px;

  ${({ fullWidth }) => fullWidth && `width:100%;`}

  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: ${({ theme }) => theme.font.typography.copy.lineHeight.xs};
  font-size: ${({ theme }) => theme.font.typography.copy.size.xs};
  @media ${({ theme }) => theme.breakpoint.md} {
    line-height: ${({ theme }) => theme.font.typography.copy.lineHeight.md};
    font-size: ${({ theme }) => theme.font.typography.copy.size.md};
  }

  cursor: pointer;

  padding: 3px 10px 3px 20px;

  ${({ variant, theme }) => {
    switch (variant) {
      case 'secondary': {
        return `
          background: ${theme.colors.secondary};
          border: 1px solid ${theme.colors.secondary};
          color: #fff;
          transition: box-shadow 0.1s ease-in-out;

          &:hover {
            -webkit-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.20);
            -moz-box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.20);
            box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.20);
          }
        `;
      }
      case 'modal': {
        return `
          background: ${theme.colors.white};
          -webkit-box-shadow: 0px 15px 27px 7px rgba(0,0,0,0.09);
          -moz-box-shadow: 0px 15px 27px 7px rgba(0,0,0,0.09);
          box-shadow: 0px 15px 27px 7px rgba(0,0,0,0.09);

          padding: 9.25px 36px 9.25px 26px;
          font-weight: ${theme.font.weight.regular};
          font-size: 18px;
          line-height: 25px;
          letter-spacing:0.36px;

          color: ${theme.colors.primary};

          display: flex;
          @media ${theme.breakpoint.sm} {
            display: inline-flex;

          }
          align-items: center;
          justify-content: flex-start;

          ${StyledArrow} {
            display:none;
          }
        `;
      }
      case 'primary':
      default: {
        return `
          background: rgba(255,255,255,0);
          border: 1px solid #000;

          &:hover {
            background: ${theme.colors.secondary};
            border: 1px solid ${theme.colors.secondary};
            color: #fff;

            svg g {
              stroke: white;
            }
          }
          `;
      }
    }
  }}
`;

const ButtonCss = css`
  color: inherit;
  text-decoration: none;
  p {
    margin: 0px;
  }
`;

const ButtonLink = styled(Link)`
  ${ButtonCss}
`;
const ButtonNoLink = styled.span`
  ${ButtonCss}
`;

const SocialIconWrapper = styled.div`
  flex-shrink: 0;

  position: relative;

  width: 32px;
  height: 27px;
  margin-right: 15px;
`;

const StyledSocialIcon = styled(SocialIcon)`
  position: absolute;
  top: -2px;
  left: 0;
`;

const Button = ({ nolink, fullWidth, link, modalSocial, variant, children, arrow, ...props }) => {
  const iconRef = useRef(null);

  const onMouseEnterHandler = () => {
    if (variant !== 'modal') {
      return null;
    }
    if (iconRef === null) {
      return null;
    }
    try {
      iconRef.current.children[0].classList.add('hover');
    } catch (e) {
      console.log(e);
    }
    return true;
  };

  const onMouseLeaveHandler = () => {
    if (variant !== 'modal') {
      return null;
    }
    if (iconRef === null || iconRef.current === null) {
      return null;
    }
    try {
      iconRef.current.children[0].classList.remove('hover');
    } catch (e) {
      console.log(e);
    }

    return true;
  };

  // eslint-disable-next-line no-shadow
  const NoLinkOrLink = ({ nolink, children, ...props }) => {
    if (nolink === true) {
      return <ButtonNoLink>{children}</ButtonNoLink>;
    }
    return <ButtonLink {...props}>{children}</ButtonLink>;
  };

  return (
    <NoLinkOrLink nolink={nolink} to={link}>
      <ButtonWrapper
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        fullWidth={fullWidth}
        variant={variant}
        {...props}
      >
        {variant === 'modal' && modalSocial !== 'none' && (
          <SocialIconWrapper ref={iconRef}>
            <StyledSocialIcon nolink buttonSize={32} icon={modalSocial ?? 'whatsapp'} />
          </SocialIconWrapper>
        )}
        {children}
        {arrow && (
          <StyledArrow>
            <Arrow color={variant === 'secondary' ? '#fff' : '#000'} />
          </StyledArrow>
        )}
      </ButtonWrapper>
    </NoLinkOrLink>
  );
};

Button.defaultProps = {
  variant: 'primary',
  link: '#',
  fullWidth: false,
  arrow: true,
  modalSocial: 'none',
  nolink: false,
};

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'modal']),
  fullWidth: PropTypes.bool,
  arrow: PropTypes.bool,
  modalSocial: PropTypes.string,
  link: PropTypes.string,
  nolink: PropTypes.bool,
};

const Btn = styled(Button)``;

export default Btn;
