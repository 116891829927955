import React from 'react';
import styled from 'styled-components';

import { makeColReady } from '../../../../styles/mixins';

const Col = styled.div`
  ${makeColReady()}

  width:100%;

  padding-top: 3.125rem;

  @media ${({ theme }) => theme.breakpoint.lg} {
    width: ${100 / 3}%;

    padding-top: 4.375rem;
  }
`;

const FooterCol = ({ children }) => {
  return <Col>{children}</Col>;
};

export default FooterCol;
