import React, { useReducer, useState } from 'react';

import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../styles/GlobalStyle';
import { theme } from '../styles/theme';

import DrawerContext, { drawerInitState, drawerReducer } from '~contexts/DrawerContext';
import ThemeContext from '~contexts/ThemeContext';

import Layout from '~components/Layout/Layout';
import PageTransition from '~util/PageTransition';

import WhatsAppWidget from '../components/util/WhatsAppWidget';

import OnDrawerScrollLock from '~util/onDrawerScrollLock';

const MainTemplate = ({ children, location }) => {
  const [drawerState, drawerDispatch] = useReducer(drawerReducer, drawerInitState);
  const [themeState, setThemeState] = useState(null);

  const renderWhatsAppWidget = () => {
    if (
      typeof themeState?.whatsapp !== 'undefined' &&
      themeState?.whatsapp !== null &&
      themeState?.whatsapp !== ''
    ) {
      return <WhatsAppWidget href={themeState?.whatsapp} />;
    }
    return null;
  };

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <ThemeContext.Provider value={{ themeState, setThemeState }}>
          <DrawerContext.Provider value={{ drawerState, drawerDispatch }}>
            <OnDrawerScrollLock>
              <Layout themeState={themeState}>
                <PageTransition location={location}>{children}</PageTransition>
              </Layout>
            </OnDrawerScrollLock>
          </DrawerContext.Provider>
        </ThemeContext.Provider>
        {renderWhatsAppWidget()}
      </ThemeProvider>
    </>
  );
};

export default MainTemplate;
