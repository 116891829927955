import React from 'react';
import styled from 'styled-components';
import Arrow from '~components/util/arrow';

const StyledArrow = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  border-radius: 999px;

  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: ${({ theme }) => theme.font.typography.copy.lineHeight.xs};
  font-size: ${({ theme }) => theme.font.typography.copy.size.xs};
  @media ${({ theme }) => theme.breakpoint.md} {
    line-height: ${({ theme }) => theme.font.typography.copy.lineHeight.md};
    font-size: ${({ theme }) => theme.font.typography.copy.size.md};
  }

  cursor: pointer;

  padding: 3px 10px 3px 20px;

  background: ${({ theme }) => theme.colors.secondary};
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  color: #fff;
  transition: box-shadow 0.1s ease-in-out;

  &:hover {
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
  }
`;

const ButtonText = styled.span`
  display: flex;

  align-items: center;
  justify-content: center;

  color: inherit;
  text-decoration: none;
  p {
    margin: 0px;
  }
`;

const Button = ({ children, ...props }) => {
  return (
    <ButtonWrapper {...props}>
      <ButtonText>
        {children}
        <StyledArrow>
          <Arrow color="#fff" />
        </StyledArrow>
      </ButtonText>
    </ButtonWrapper>
  );
};

const Btn = styled(Button)``;

export default Btn;
