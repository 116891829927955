import React from 'react';

import Header from './Header/Header';
import MenuBlok from '../Menu/MenuBlok';
import FooterBlok from './Footer/FooterBlok';
import WeShouldTalkModal from '../Modal/Modals/WeShouldTalk';

import { getSafe } from '~util/util';

const Layout = ({ themeState, children }) => {
  return (
    <>
      <Header
        blok={getSafe(() => themeState.header[0], null)}
        logoType={getSafe(() => themeState.previousLocation, null)}
      />
      <MenuBlok
        blok={getSafe(() => themeState.main_menu[0], null)}
        logoType={getSafe(() => themeState.previousLocation, null)}
      />
      {children}
      <FooterBlok blok={getSafe(() => themeState.footer[0], null)} />
      <WeShouldTalkModal transparent open />
    </>
  );
};

export default Layout;
