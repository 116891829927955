export const getSafe = (fn, defaultVal) => {
  try {
    return fn();
  } catch (e) {
    return defaultVal;
  }
};

export const transformImage = (image, option, webp = false) => {
  const cleanUrl = image.replace('https:', '');
  const imageService = '//img2.storyblok.com/';
  const path = cleanUrl.replace('//a.storyblok.com', '');
  return imageService + option + (webp ? 'filters:format(webp)/' : '') + path;
};

export const LightenDarkenColor = (color, amt) => {
  let usePound = false;
  let col = color;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  // eslint-disable-next-line no-bitwise
  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  // eslint-disable-next-line no-bitwise
  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  // eslint-disable-next-line no-bitwise
  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  // eslint-disable-next-line no-bitwise
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};
