import React from 'react';
import styled from 'styled-components';

import Link from '~components/util/Link';
import SbEditable from '../../../../util/SbEditable';

export const LinkWrapper = styled.div`
  overflow: hidden;
  word-break: break-all;

  flex: 1 1 50%;

  padding-top: 12px;
  padding-bottom: 0px;

  overflow: hidden;
  word-break: break-all;
`;

const StyledLink = styled(Link)`
  font-size: 1.125rem;
  text-decoration: none;

  color: ${({ theme }) => theme.colors.black};
  transition: 0.3s ease-in-out color;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
  text-transform: uppercase;
`;

const FooterLink = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <LinkWrapper>
        <StyledLink title={blok.label} to={blok?.link?.cached_url}>
          {blok.label}
        </StyledLink>
      </LinkWrapper>
    </SbEditable>
  );
};

export default FooterLink;
