import React from 'react';
import styled from 'styled-components';

const FooterColContainer = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
`;

const FooterColContainerComponent = ({ children }) => {
  return <FooterColContainer>{children}</FooterColContainer>;
};

export default FooterColContainerComponent;
