/* eslint-disable camelcase */
import React, { useContext } from 'react';

import Menu from './Menu/Menu';
import SbEditable from '../util/SbEditable';
import MenuItem from './MenuItem/MenuItem';
import MenuSubItem from './MenuSubItem/MenuSubItem';
import DrawerContext from '~contexts/DrawerContext';

const MenuBlok = (props) => {
  const { drawerState, drawerDispatch } = useContext(DrawerContext);
  if (props.blok !== null) {
    const menuItems = props.blok.menu_items;

    const items = Object.keys(menuItems).map((key) => {
      const subMenuItems = menuItems[key].sub_menu_items;
      let subItems = null;
      let subMenuLabel = menuItems[key].label;
      if (
        typeof menuItems[key].mobile_label !== 'undefined' &&
        menuItems[key].mobile_label.length !== 0
      ) {
        subMenuLabel = menuItems[key].mobile_label;
      }
      if (Array.isArray(subMenuItems) && subMenuItems.length) {
        subItems = subMenuItems.map((subItem) => {
          return (
            <SbEditable key={subItem._uid} content={subItem}>
              <MenuSubItem url={subItem.link?.cached_url} label={subItem.label} />
            </SbEditable>
          );
        });
      }
      return (
        <SbEditable key={menuItems[key]._uid} content={menuItems[key]}>
          <MenuItem
            subLabel={subMenuLabel}
            label={menuItems[key].label}
            url={menuItems[key]?.link?.cached_url}
          >
            {subItems}
          </MenuItem>
        </SbEditable>
      );
    });

    if (menuItems !== null) {
      return (
        <SbEditable content={props.blok}>
          <Menu
            closeAction={() => drawerDispatch({ type: 'close' })}
            logoType={props.logoType}
            key={props.blok._uid}
            blok={props.blok}
            open={drawerState.open}
          >
            {items}
          </Menu>
        </SbEditable>
      );
    }
  }
  return null;
};

export default MenuBlok;
