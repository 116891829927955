import Bold from './AvenirNext-Bold.woff';
import Bold2 from './AvenirNext-Bold.woff2';
import BoldItalic from './AvenirNext-BoldItalic.woff';
import BoldItalic2 from './AvenirNext-BoldItalic.woff2';

import Regular from './AvenirNext-Regular.woff';
import Regular2 from './AvenirNext-Regular.woff2';
import Italic from './AvenirNext-Italic.woff';
import Italic2 from './AvenirNext-Italic.woff2';

import UltraLight from './AvenirNext-UltraLight.woff';
import UltraLight2 from './AvenirNext-UltraLight.woff2';
import UltraLightItalic from './AvenirNext-UltraLightItalic.woff';
import UltraLightItalic2 from './AvenirNext-UltraLightItalic.woff2';

export default {
  bold: Bold,
  bold2: Bold2,
  boldItalic: BoldItalic,
  boldItalic2: BoldItalic2,
  regular: Regular,
  regular2: Regular2,
  italic: Italic,
  italic2: Italic2,
  ultraLight: UltraLight,
  ultraLight2: UltraLight2,
  ultraLightItalic: UltraLightItalic,
  ultraLightItalic2: UltraLightItalic2,
};
