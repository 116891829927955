import React from 'react';
import styled from 'styled-components';
import Api from '../../../../../util/storyblok-api';

import SbEditable from '../../../../util/SbEditable';
import SanitizeHTML from '../../../../util/SanitizeHTML';

export const TextWrapper = styled.div`
  min-width: 50%;
  flex-grow: 1;

  flex: 1 1 50%;

  overflow: hidden;
  word-break: break-all;
  padding-top: 12px;
  a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s ease-in-out color;
  }

  a[href^='mailto']:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const FooterText = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <TextWrapper>
        <SanitizeHTML html={Api.richTextResolver.render(blok.content)} />
      </TextWrapper>
    </SbEditable>
  );
};

export default FooterText;
