import React from 'react';
import styled from 'styled-components';

const TitleWrapper = styled.div`
  padding-bottom: 16px;

  border-bottom: 1px solid rgba(39, 38, 39, 0.42);

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-bottom: 26px;
  }
`;

const Title = styled.span`
  font-size: 3.125rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  color: ${({ theme }) => theme.colors.primary};

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 4.375rem;
  }
`;

const FooterColTitle = ({ children }) => {
  return (
    <TitleWrapper>
      <Title>{children}</Title>
    </TitleWrapper>
  );
};

export default FooterColTitle;
