import React, { useContext } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import ThemeContext from '~contexts/ThemeContext';
import DrawerContext from '~contexts/DrawerContext';

import SbEditable from '../../util/SbEditable';
import Logo from '../../Logo/Logo';
import Hamburger from '../../Menu/Hamburger/Hamburger';
import { makeContainer, makeContainerMaxWidths } from '../../../styles/mixins';

const HeaderFliudContainer = styled.div`
  ${makeContainer}
  padding-left:0;
  padding-right: 0;
  position: relative;

  position: sticky;
  top: 0;
  z-index: 999999;
  background: ${({ theme }) => theme.colors.white};
`;

const HeaderContainer = styled.div`
  ${makeContainer}
  ${makeContainerMaxWidths}
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  height: 72px;

  @media ${({ theme }) => theme.breakpoint.md} {
    height: 104px;
  }
`;

const HeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  flex-grow: 1;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 22px;
`;

const Link = styled.a`
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.colors.primary};

  & + & {
    margin-left: 18px;
  }

  transition: 0.1s ease-in-out color;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const DesktopButtons = styled.div`
  display: none;
  @media ${({ theme }) => theme.breakpoint.md} {
    display: flex;
  }
`;

const GetInTouch = styled.span`
  line-height: 22px;
  color: ${({ theme }) => theme.colors.primary};

  padding: 4px 10px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  margin-right: 36px;
  margin-left: 35px;

  text-decoration: none;
  transition: color 0.5s ease-in-out;

  position: relative;
  overflow: hidden;

  cursor: pointer;

  &:before {
    transition: 0.5s ease-in-out;
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    z-index: -1;

    width: 200%;
    height: 100%;

    background: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    color: #fff;
  }

  &:hover:before {
    transform: translateX(55%);
  }
`;

const HeaderComponent = (props) => {
  const { themeState } = useContext(ThemeContext);
  const { drawerDispatch } = useContext(DrawerContext);

  if (props.blok !== null) {
    return (
      <SbEditable content={props.blok}>
        <HeaderFliudContainer>
          <HeaderContainer>
            <Header key={props.blok._uid} blok={props.blok}>
              <Logo type={props.logoType ? 'home' : 'default'} />
              <HeaderButtonsContainer>
                <DesktopButtons>
                  {themeState?.instagram && themeState.instagram !== '' && (
                    <Link target="_blank" href={themeState.instagram}>
                      <Icon icon={faInstagram} />
                    </Link>
                  )}
                  {themeState?.facebook && themeState.facebook !== '' && (
                    <Link target="_blank" href={themeState.facebook}>
                      <Icon icon={faFacebook} />
                    </Link>
                  )}
                  {themeState?.linkedin && themeState.linkedin !== '' && (
                    <Link target="_blank" href={themeState.linkedin}>
                      <Icon icon={faLinkedin} />
                    </Link>
                  )}
                  <GetInTouch onClick={() => drawerDispatch({ type: 'modalOpen' })}>
                    Get in touch
                  </GetInTouch>
                </DesktopButtons>
                <Hamburger
                  onClick={() => {
                    drawerDispatch({ type: 'open' });
                  }}
                />
              </HeaderButtonsContainer>
            </Header>
          </HeaderContainer>
        </HeaderFliudContainer>
      </SbEditable>
    );
  }
  return null;
};

export default HeaderComponent;
