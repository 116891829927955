import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Wrapper = styled.a`
  display: none !important;
  position: fixed;
  z-index: 999999999999;

  width: 60px;
  height: 60px;

  bottom: 20px;
  right: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  text-decoration: none;

  background-color: #25d366;
  box-shadow: 0px 1px 6px #999;

  @media ${({ theme }) => theme.breakpoint.lg} {
  display: none !important;
    width: auto;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 50px;
  }
`;

const ChatWithUs = styled.div`
  display: none !important;
  @media ${({ theme }) => theme.breakpoint.lg} {
    display: none !important;
    padding-left: 7px;

    text-decoration: none;
    color: #fff;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 30px;
`;

const WhatsAppWidget = ({ href }) => {
  return (
    <Wrapper
      href={href}
      onClick={(e) => {
        e.preventDefault();
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'WAClick',
          category: 'WhatsApp Chat Popup Button',
          action: 'Click',
          label: 'Gatsby',
        });
        window.location = href;
      }}
      alt="WhatsApp widget"
    >
      <Icon icon={faWhatsapp} />
      <ChatWithUs>Chat with us on WhatsApp</ChatWithUs>
    </Wrapper>
  );
};

export default WhatsAppWidget;
