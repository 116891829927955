import React, { useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import ThemeContext from '~contexts/ThemeContext';
import SocialIcon from '../../Button/SocialIcon';

import { makeColReady, makeCol } from '../../../styles/mixins';

const Grid = styled.div`
  ${makeColReady}
  ${makeCol(1, 1)}
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;

  @media ${({ theme }) => theme.breakpoint.md} {
    min-height: 80vh;
    display: grid;
    grid-gap: 0px;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    grid-auto-rows: 3rem;
  }
`;

const Socials = styled.div`
  ${makeColReady}
  grid-column-end: -1;

  display: flex;

  padding-top: 2rem;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding: 0;
    padding-top: 2rem;
  }

  a + a {
    margin-left: 2rem;
  }
  @media ${({ theme }) => theme.BreakpointDown.md} {
    padding-top: 10px;
  }
  @media (max-height: 549px) {
    display: none;
  }
`;

const MenuGrid = ({ children }) => {
  const gridRef = useRef(null);
  const { themeState } = useContext(ThemeContext);

  const resizeGridItem = (item) => {
    if (gridRef == null) {
      return;
    }
    try {
      const grid = gridRef.current;

      const rowHeight = parseInt(
        window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'),
        10,
      );
      const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'), 10);
      const rowSpan = Math.ceil(
        (item.querySelector('.menu-item-content').getBoundingClientRect().height + rowGap) /
          (rowHeight + rowGap),
      );
      // eslint-disable-next-line no-param-reassign
      item.style.gridRowEnd = `span ${rowSpan}`;
    } catch (e) {
      // TODO: handle the error
    }
  };

  const resizeAllGridItems = () => {
    const allItems = document.getElementsByClassName('menu-item-wrapper');
    for (let x = 0; x < allItems.length; x += 1) {
      resizeGridItem(allItems[x]);
    }
  };

  useEffect(() => {
    resizeAllGridItems();
    window.addEventListener('resize', resizeAllGridItems);
  }, [gridRef]);

  return (
    <Grid ref={gridRef}>
      {children}
      <Socials>
        {/* {themeState?.whatsapp && themeState.whatsapp !== '' && (
          <SocialIcon href={themeState.whatsapp} buttonSize={30} icon="whatsapp" />
        )} */}
        {themeState?.instagram && themeState.instagram !== '' && (
          <SocialIcon href={themeState.instagram} buttonSize={30} icon="instagram" />
        )}
        {themeState?.facebook && themeState.facebook !== '' && (
          <SocialIcon href={themeState.facebook} buttonSize={30} icon="facebook" />
        )}
        {themeState?.linkedin && themeState.linkedin !== '' && (
          <SocialIcon href={themeState.linkedin} buttonSize={30} icon="linkedin" />
        )}
      </Socials>
    </Grid>
  );
};

export default MenuGrid;
