import Storyblok from 'storyblok-js-client';
import config from '../../gatsby-config';

const sbConfigs = config.plugins.filter((item) => {
  return item.resolve === 'gatsby-source-storyblok';
});
const sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {};

const Api = new Storyblok({
  accessToken: sbConfig.options.accessToken,
});




export default Api;
