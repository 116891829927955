import React from 'react';
import styled, { css } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWhatsapp,
  faFacebookF,
  faLinkedin,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const availableIcons = {
  whatsapp: faWhatsapp,
  facebook: faFacebookF,
  instagram: faInstagram,
  linkedin: faLinkedin,
  mail: faEnvelope,
};

const FontAwesome = styled(FontAwesomeIcon)`
  position: relative;
  color: #232324;
  transition: 0.5s;
  z-index: 3;
`;

const LinkCss = css`
  width: ${({ buttonSize }) => buttonSize ?? 80}px !important;
  height: ${({ buttonSize }) => buttonSize ?? 80}px !important;
  background-color: #f1f1f1;
  text-align: center;
  font-size: ${({ buttonSize }) => {
    if (Number.isInteger(buttonSize / 2)) {
      return buttonSize / 2;
    }
    return 32;
  }}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #f1f1f1;
  z-index: 1;

  &:hover ${FontAwesome}, &.hover ${FontAwesome} {
    color: #fff;
    transform: rotateY(360deg);
  }

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: 0.5s;
    z-index: 2;

    background: ${({ theme }) => theme.colors.secondary};
  }

  &.hover:before,
  &:hover:before {
    top: 0;
  }
`;

const Link = styled.a`
  ${LinkCss}
`;

const Nolink = styled.span`
  ${LinkCss}
`;

const SocialIcon = ({ buttonSize, target, nolink, icon, ...props }) => {
  // eslint-disable-next-line no-shadow
  const LinkOrSpan = ({ isLink, children, ...props }) => {
    if (isLink === false) {
      return (
        <Nolink
          buttonSize={buttonSize}
          target={target ?? `_blank`}
          className="social-icon"
          {...props}
        >
          {children}
        </Nolink>
      );
    }
    return (
      <Link buttonSize={buttonSize} target={target ?? `_blank`} className="social-icon" {...props}>
        {children}
      </Link>
    );
  };

  return (
    <LinkOrSpan
      isLink={nolink && !nolink}
      buttonSize={buttonSize}
      target={target ?? `_blank`}
      data-social-icon={icon}
      {...props}
    >
      <FontAwesome className="icon" icon={availableIcons[icon] ?? availableIcons.facebook} />
    </LinkOrSpan>
  );
};

export default SocialIcon;
