import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import DrawerContext from '~contexts/DrawerContext';

const onDrawerScrollLock = ({ children }) => {
  const { drawerState } = useContext(DrawerContext);

  return (
    <>
      <Helmet>
        <body
          data-drawer={drawerState.open === true || drawerState.modal === true ? 'open' : 'close'}
        />
      </Helmet>
      {children}
    </>
  );
};

export default onDrawerScrollLock;
