import React from 'react';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

const defaultOptions = {
  allowedTags: [
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'b',
    'i',
    'strike',
    'u',
    'ul',
    'li',
    'ol',
    'hr',
    'blockquote',
    'a',
    'img',
    'span',
    'br',
    'div'
  ],
  allowedAttributes: {
    a: ['href', 'uuid', 'linktype', 'target'],
    span: ['class'],
    img: ['src', 'alt', 'title'],
    div: ['style']
  },
  allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
};

const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(dirty, { ...defaultOptions, ...options }),
});

const AlteredDiv = styled.div`
  & p {
    margin: ${(props) => (props.nomargin ? '0px' : 'auto')};
  }
`;

const SanitizeHTML = ({ html, options, ...props }) => {
  // Prevent break line after & character
  let retHtml = html;
  if (typeof html === 'string') {
    retHtml = html.replace('&amp; ', '&amp;&nbsp;');
  }

  return (
    // eslint-disable-next-line react/no-danger
    <AlteredDiv {...props} dangerouslySetInnerHTML={sanitize(retHtml, options)} />
  );
};

export default SanitizeHTML;
