import React from 'react';

import Footer from './Footer/Footer';
import FooterCol from './Footer/FooterCol';
import FooterColTitle from './Footer/FooterColTitle';
import FooterColContainer from './Footer/FooterColContainer';
import SbEditable from '../../util/SbEditable';

import FooterLink from './Footer/FooterComponents/FooterLink';
import FooterText from './Footer/FooterComponents/FooterText';

import getComponent from '~components/util/getComponent';

const FooterComponents = {
  footer_link: FooterLink,
  footer_text: FooterText,
};

const FooterWrapper = (props) => {
  if (props.blok !== null) {
    const footerColumnsJson = props.blok.columns;
    let footerColumns = null;
    if (typeof footerColumnsJson !== 'undefined' && footerColumnsJson !== null) {
      footerColumns = Object.keys(footerColumnsJson).map((key) => {
        return (
          <SbEditable key={footerColumnsJson[key]._uid} content={footerColumnsJson[key]}>
            <FooterCol>
              <FooterColTitle>{footerColumnsJson[key].title}</FooterColTitle>
              <FooterColContainer>
                {footerColumnsJson[key].body &&
                  footerColumnsJson[key].body.map((blok) => {
                    return React.createElement(getComponent(FooterComponents, blok.component), {
                      key: blok._uid,
                      blok,
                    });
                  })}
              </FooterColContainer>
            </FooterCol>
          </SbEditable>
        );
      });
    }

    return (
      <SbEditable content={props.blok}>
        <Footer additionalInfo={props?.blok?.additional_info ?? ''}>{footerColumns}</Footer>
      </SbEditable>
    );
  }
  return null;
};

export default FooterWrapper;
