import React from 'react';

import SbOriginal from 'storyblok-react';

/*
 * Storyblok wrapper. Used to wrap if prop.content passed.
 *
 */

const SbEditable = (props) => {
  if (typeof props.content === 'undefined') {
    return props.children;
  } 
    if (typeof props.content._uid === 'undefined') {
      return props.children;
    } 
      return <SbOriginal {...props}>{props.children}</SbOriginal>;
    
  
};

export default SbEditable;
