import React from 'react';

import Fonts from './fonts';
import Typography from './typography';
import Grid from './grid';

const GlobalStyle = () => {
  return (
    <>
      <Fonts />
      <Typography />
      <Grid />
    </>
  );
};

export default GlobalStyle;
