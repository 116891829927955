import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';

import DrawerHeader from './DrawerHeader';
import { makeContainer, makeContainerMaxWidths, makeRow } from '../../styles/mixins';

const Wrapper = styled(motion.div)`
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  @media ${({ theme }) => theme.breakpoint.md} {
    overflow: hidden;
  }

  outline: 0 !important; /* Storyblok outline there was annoying */
  box-shadow: none !important;

  top: 0;
  left: 100%;

  z-index: 99999999;

  width: 100%;
  height: 100vh;

  margin-bottom: 3vh;

  border-bottom: 3vh solid ${({ theme }) => theme.colors.secondary};
  background: ${({ theme }) => theme.colors.white};
  ${({ transparent }) => transparent && `background: rgba(255,255,255,0.96);`}
`;

const Container = styled.div`
  ${makeContainer}
  ${makeContainerMaxWidths}
`;

const Row = styled.div`
  ${makeRow}/* overflow:hidden; */
`;

const Drawer = ({ transparent, logoType, closeAction, open, children }) => {
  const controls = useAnimation();

  /**
   * closeAction may be not available in storybook
   */
  const closeHandler = () => {
    if (typeof closeAction !== 'undefined') {
      closeAction();
    }
  };

  useEffect(() => {
    controls.start(open ? 'open' : 'close');
  }, [open, controls]);

  const menuWrapperStyles = {
    close: {
      x: 0,
    },
    open: {
      x: '-100%',
    },
  };

  return (
    <Wrapper
      transparent={transparent}
      animate={controls}
      variants={menuWrapperStyles}
      transition={{ type: 'spring', damping: 60, stiffness: 180 }}
    >
      <Container>
        <Row>
          <DrawerHeader logoType={logoType} closeHandler={closeHandler} />
        </Row>
        <Row>{children}</Row>
      </Container>
    </Wrapper>
  );
};

export default Drawer;
