import React from 'react';
import styled from 'styled-components';

const linePropsMixin = (hamburger) => {
  return `
        width: 100%;
        height: ${hamburger.hLineHeight};
        background-color: ${hamburger.hLineColor}; 
        border-radius: ${hamburger.hBorderRadius};
        position: absolute;
        transition-property: transform, opacity;
        transition-timing-function: ease;
        transition-duration: ${hamburger.hTransitionTime};
    `;
};

const Container = styled.div`
  width: ${({ theme }) => {
    return theme.hamburger.hWidth;
  }};
  height: ${({ theme }) => {
    return theme.hamburger.hHeight;
  }};
  position: relative;
`;
const Inner = styled.div`
  ${({ theme }) => {
    return linePropsMixin(theme.hamburger);
  }}
  top: 50%;
  transform: translate(
    ${({ theme }) => {
      return theme.hamburger.hInitialOpposition;
    }},
    -50%
  );
  opacity: 1;

  &:before,
  &:after {
    ${({ theme }) => {
      return linePropsMixin(theme.hamburger);
    }}

    content: "";
    opacity: 1;
    transform: translate(
      -${({ theme }) => {
          return theme.hamburger.hInitialOpposition;
        }},
      0
    );
  }

  &:before {
    top: -${({ theme }) => {
        return theme.hamburger.hVerticalGap;
      }};
  }

  &:after {
    top: ${({ theme }) => {
      return theme.hamburger.hVerticalGap;
    }};
  }
`;
const Hidden = styled.div`
    opacity: 0;
    ${({ theme }) => {
      return linePropsMixin(theme.hamburger);
    }}

    background-color: ${({ theme }) => {
      return theme.hamburger.hHoverLineColor;
    }};
    top: 50%;
    transform: translate(${({ theme }) => {
      return theme.hamburger.hHideDistance;
    }}px, -50%);

    &:before,
    &:after {
        ${({ theme }) => {
          return linePropsMixin(theme.hamburger);
        }}
        background-color: ${({ theme }) => {
          return theme.hamburger.hHoverLineColor;
        }};

        content: "";
        transform: translate(${({ theme }) => {
          return theme.hamburger.hHideDistance * 2;
        }}px, 0);
    }

    &:before {
        top: -${({ theme }) => {
          return theme.hamburger.hVerticalGap;
        }};
    }

    &:after {
        top: ${({ theme }) => {
          return theme.hamburger.hVerticalGap;
        }};
    }
`;

const HamburgerWrapper = styled.div`
  padding: ${({ theme }) => {
    return theme.hamburger.hPadding;
  }};
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  background-color: ${({ theme }) => {
    return theme.hamburger.hBackgroundColor;
  }};

  &:hover ${Inner} {
    transform: translate(
      -${({ theme }) => {
          return theme.hamburger.hHideDistance;
        }}px,
      50%
    );
    opacity: ${({ theme }) => {
      return theme.hamburger.hTransitionOpacity;
    }};
  }

  &:hover ${Inner}:before, &:hover ${Inner}:after {
    transform: translate(
      ${({ theme }) => {
        return theme.hamburger.hHideDistance * 2;
      }}px,
      0
    );
    opacity: $h-transition-opacity;
  }

  &.is-active ${Inner} {
    display: none;
  }

  &:hover ${Hidden} {
    opacity: 1;
    transform: translate(0, -50%);
  }

  &:hover ${Hidden}:before, &:hover ${Hidden}:after {
    opacity: 1;
    transform: translate(0, 0);
  }

  &.is-active ${Hidden} {
    opacity: 1;
    transform: rotate(45deg);
  }

  &.is-active ${Hidden}:before {
    transform: translate(
        0,
        ${({ theme }) => {
          return theme.hamburger.hVerticalGap;
        }}
      )
      rotate(90deg);
    transform-origin: center;
  }

  &.is-active ${Hidden}:after {
    transform-origin: center;
    transform: translate(
        0,
        -${({ theme }) => {
            return theme.hamburger.hVerticalGap;
          }}
      )
      rotate(0);
  }
`;

const Hamburger = (props) => {
  return (
    <HamburgerWrapper onClick={props.onClick} className="Wrapper">
      <Container className="Container">
        <Inner className="Inner" />
        <Hidden className="Hidden" />
      </Container>
    </HamburgerWrapper>
  );
};

export default Hamburger;
