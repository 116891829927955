import React from 'react';

import Drawer from '../../Drawer/Drawer';
import MenuGrid from './MenuGrid';

const Menu = ({ closeAction, logoType, open, children }) => {
  return (
    <Drawer logoType={logoType} closeAction={closeAction} open={open}>
      <MenuGrid>{children}</MenuGrid>
    </Drawer>
  );
};

export default Menu;
