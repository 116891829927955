/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable consistent-return */
import React, { useState, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Link from '~components/util/Link';

import DrawerContext from '~contexts/DrawerContext';
import * as mixins from '../../../styles/mixins';
import Arrow from '../../util/arrow';

const MenuItemWrapper = styled.div`
  ${mixins.makeColReady()}
  ${mixins.makeCol()}

  flex: initial;

  width: 100%;

  padding-top: 3px;
  padding-bottom: 3px;

  outline: 0 !important; /* Storyblok outline there was annoying */
  box-shadow: none !important;

  @media ${({ theme }) => theme.breakpoint.md} {
    flex: 0 0 100%;

    padding-top: 30px;
    padding-bottom: 30px;

    padding-left: 0;
    padding-right: 0;
  }
`;

/** Needs to be moved into seperate file as we gonna reuse it */
const CircleButtonIcon = styled.div`
  width: 32px;
  height: 32px;

  transform: scale(0);
  transition: 0.5s transform ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  visibility: hidden;

  margin-right: 25px;

  border-radius: 50%;

  background: ${({ theme }) => theme.colors.secondary};

  @media ${({ theme }) => theme.breakpoint.md} {
    width: 52px;
    height: 52px;
    visibility: visible;
  }
`;

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  &:focus {
    outline: 0;
  }

  font-size: 25px;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  white-space: nowrap;

  transform: translateX(-57px);
  transition: 0.5s color ease-in-out, 0.5s transform ease-in-out;

  color: ${({ theme }) => theme.colors.primary};

  text-decoration: none;

  @media ${({ theme }) => theme.breakpoint.md} {
    transform: translateX(-77px);
    font-size: ${({ theme }) => theme.font.typography.h1.size.md};

    &:hover {
      transform: translateX(0);
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:hover ${CircleButtonIcon} {
    transform: scale(1);
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const SubItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  transition: max-height 0.5s ease-in-out;

  max-height: 0;
  overflow: hidden;

  @media ${({ theme }) => theme.breakpoint.md} {
    max-height: initial;

    margin-top: 0.5rem;
  }
`;

const MobileDropdownTrigger = styled.span`
  display: none;
  align-items: center;

  cursor: pointer;

  padding-top: 16px;

  font-size: ${({ theme }) => theme.font.typography.h1.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.bold};

  white-space: nowrap;

  transition: 0.5s color ease-in-out, 0.5s transform ease-in-out;

  color: ${({ theme }) => theme.colors.primary};

  text-decoration: none;

  &.active {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &.active + ${SubItemsWrapper} {
    max-height: 300px;
  }

  @media ${({ theme }) => theme.breakpoint.md} {
    display: none;
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const MenuItemContent = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
`;

const MenuItem = ({ url, label, subLabel, children }) => {
  const themeContext = useContext(ThemeContext);
  const { drawerDispatch } = useContext(DrawerContext);

  const SubItems = () => {
    const [openSubMenu, setOpenSubMenu] = useState(true);

    const subMenuOnClickHandler = () => {
      setOpenSubMenu(!openSubMenu);
    };

    if (children != null) {
      return (
        <>
          <MobileDropdownTrigger
            className={openSubMenu ? 'active' : ''}
            open={openSubMenu}
            onClick={subMenuOnClickHandler}
          >
            {subLabel}
            <div style={{ marginLeft: '10px' }}>
              <Arrow
                direction={openSubMenu ? 'top' : 'bottom'}
                size={16}
                color={openSubMenu ? themeContext.colors.secondary : themeContext.colors.primary}
                shaft={false}
              />
            </div>
          </MobileDropdownTrigger>
          <SubItemsWrapper>{children}</SubItemsWrapper>
        </>
      );
    }
    return null;
  };

  const getPathName = () => {
    if (typeof window === 'undefined') {
      return;
    }
    return window.location?.pathname;
  };

  return (
    <MenuItemWrapper className="menu-item-wrapper">
      <MenuItemContent className="menu-item-content">
        <MenuLink
          className="menu-item"
          to={url}
          state={{ prevPath: getPathName() }}
          onClick={() => drawerDispatch({ type: 'close' })}
        >
          <CircleButtonIcon>
            <Arrow size={36} />
          </CircleButtonIcon>
          {label}
        </MenuLink>
        <SubItems />
      </MenuItemContent>
    </MenuItemWrapper>
  );
};

export default MenuItem;
