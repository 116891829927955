import React from 'react';
import PropTypes from 'prop-types';

const arrow = ({ size, direction, color, width, shaft }) => {
  const getRotateDegree = () => {
    switch (direction) {
      case 'top':
        return 270;
      case 'left':
        return 180;
      case 'bottom':
        return 90;
      case 'right':
        return 0;
      default:
        return 0;
    }
  };

  return (
    <>
      <svg
        style={{ transform: `rotate(${getRotateDegree()}deg)` }}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 32 32"
      >
        <g
          fill="none"
          stroke={color}
          strokeWidth={width}
          strokeLinejoin="round"
          strokeMiterlimit="10"
        >
          <path
            className="arrow-icon--arrow"
            d={`${
              shaft
                ? 'M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98'
                : 'M9.79 4.93L22.21 16l-12.42 11.07'
            }`}
          />
        </g>
      </svg>
    </>
  );
};

arrow.defaultProps = {
  size: 32,
  direction: 'right',
  color: '#fff',
  width: 1.5,
  shaft: true,
};

arrow.propTypes = {
  size: PropTypes.number,
  direction: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
  color: PropTypes.string,
  width: PropTypes.number,
  shaft: PropTypes.bool,
};

export default arrow;
