import React from 'react';

import Drawer from '../Drawer/Drawer';

const Modal = ({ transparent, closeAction, open, children }) => {
  return (
    <Drawer transparent={transparent} closeAction={closeAction} open={open}>
      {children}
    </Drawer>
  );
};

export default Modal;
