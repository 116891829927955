import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';

export default createGlobalStyle`
    * {
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
        -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important; 
        outline: none !important;
    } 
    body {
        margin:0;
        font-family: "Avenir Next";
        font-size: ${theme.font.typography.copy.size.xs};
        line-height: ${theme.font.typography.copy.lineHeight.sm};
        @media ${theme.breakpoint.md} {
            font-size: ${theme.font.typography.copy.size.md};
            line-height: ${theme.font.typography.copy.lineHeight.md};
        }
    }
    h1 {
        font-weight: ${theme.font.weight.bold};
        font-size: ${theme.font.typography.h1.size.xs};
        line-height: ${theme.font.typography.h1.lineHeight.xs};
        @media ${theme.breakpoint.md} {
            font-size: ${theme.font.typography.h1.size.md};
            line-height: ${theme.font.typography.h1.lineHeight.md};
        }
        &.hero {
            font-size: ${theme.font.typography.hero.size.xs};
            line-height: ${theme.font.typography.hero.lineHeight.xs};
            @media ${theme.breakpoint.md} {
                font-size: ${theme.font.typography.hero.size.md};
                line-height: ${theme.font.typography.hero.lineHeight.md};
            }
        }
        &.light {
            font-weight: ${theme.font.weight.light};
        } 
    }
    h2 {
        font-weight: ${theme.font.weight.bold};
        font-size: ${theme.font.typography.h2.size.xs};
        line-height: ${theme.font.typography.h2.lineHeight.xs};
        @media ${theme.breakpoint.md} {
            font-size: ${theme.font.typography.h2.size.md};
            line-height: ${theme.font.typography.h2.lineHeight.md};
        }
        &.light {
            font-weight: ${theme.font.weight.light};
        } 
    }
    h3 {
        font-weight: ${theme.font.weight.regular};
        font-size: ${theme.font.typography.h3.size.xs};
        line-height: ${theme.font.typography.h3.lineHeight.xs};
        @media ${theme.breakpoint.md} {
            font-size: ${theme.font.typography.h3.size.md} ;
            line-height: ${theme.font.typography.h3.lineHeight.md} ;
        }
        &.light {
            font-weight: ${theme.font.weight.light};
        } 
    }
    input, select, textarea {
        font-family: "Avenir Next";
    }

    .pink {
      color: #F84682;
    }

`;
