import React, { useContext } from 'react';
import styled from 'styled-components';

import ThemeContext from '~contexts/ThemeContext';
import Button from '~components/Button/Button';

const Pos = styled.div`
  padding-top: 22px;
  padding-bottom: 22px;
  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 0;
    padding-bottom: 0;
  }

  display: none;
  @media ${({ theme }) => theme.breakpoint.lg} {
    display: block;
  }
  ${({ mobile, theme }) =>
    mobile &&
    `
    display:block;
    @media ${theme.breakpoint.lg} {
      display: none; 
    }`}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${({ theme }) => theme.breakpoint.lg} {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  position: relative;
  margin-top: 22px;
  margin-bottom: 22px;
  padding-top: 32px;
  padding-bottom: 32px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;

    width: 200vw;
    transform: translateX(-50%);

    height: 22px;
    background: #f5f5f5;
    @media ${({ theme }) => theme.breakpoint.lg} {
      display: none;
    }
  }

  &:before {
    bottom: 100%;
  }
  &:after {
    top: 100%;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  @media ${({ theme }) => theme.breakpoint.lg} {
    & + & {
      margin-left: 15px;
    }
  }

  .whatsapp-always-on [data-social-icon='whatsapp'] svg {
    color: #fff !important;
  }
  .whatsapp-always-on [data-social-icon='whatsapp']:before {
    top: 0 !important;
    background: #25d366;
  }
`;

const OrWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
`;

const ContactButtons = ({ mobile }) => {
  const { themeState } = useContext(ThemeContext);
  return (
    <Pos mobile={mobile}>
      <Wrapper>
        {/* <ButtonWrapper>
          <Button
            className="whatsapp-always-on"
            link={themeState?.whatsapp}
            modalSocial="whatsapp"
            variant="modal"
          >
            WhatsApp us
          </Button>
        </ButtonWrapper>
        <OrWrapper>OR</OrWrapper> */}
        <ButtonWrapper>
          <Button link={`mailto:${themeState?.email}`} modalSocial="mail" variant="modal">
            {themeState?.email}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Pos>
  );
};

export default ContactButtons;
