import React from 'react';
import styled from 'styled-components';

import Logo from '../Logo/Logo';
import { makeColReady, makeCol } from '../../styles/mixins';
import Arrow from '../util/arrow';

const HeaderContainer = styled.div`
  ${makeColReady}
  ${makeCol}

    display:flex;
  align-items: center;

  height: 72px;
  @media ${({ theme }) => theme.breakpoint.md} {
    height: 104px;
  }
`;

const PosCol = styled.div`
  ${makeColReady}

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-left:0;
    padding-right:0;
  }
`;

const ControllerContainer = styled.div`
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ArrowWrapper = styled.div`
  cursor: pointer;

  &:hover svg g {
    stroke: ${({ theme }) => theme.colors.secondary};
  }
`;

const DrawerHeader = ({ logoType, closeHandler }) => {
  return (
    <HeaderContainer>
      <PosCol>
        <Logo type={logoType ? 'home' : 'default'} onClick={closeHandler} />
      </PosCol>
      <ControllerContainer>
        <ArrowWrapper onClick={closeHandler}>
          <Arrow direction="left" color="#000" size={50} />
        </ArrowWrapper>
      </ControllerContainer>
    </HeaderContainer>
  );
};

export default DrawerHeader;
