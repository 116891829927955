import React, { useContext } from 'react';

import ContactForm from '~components/PageSpecific/ContactUs/ContactForm/ContactForm';
import Modal from '../Modal';

import DrawerContext from '~contexts/DrawerContext';

const WeShouldTalk = (props) => {
  const { drawerState, drawerDispatch } = useContext(DrawerContext);

  return (
    <Modal
      {...props}
      open={drawerState.modal}
      closeAction={() => drawerDispatch({ type: 'modalClose' })}
    >
      <ContactForm modal />
    </Modal>
  );
};

export default WeShouldTalk;
